import Header from "../components/Header";
import { BackgroundPattern1 } from "../components/Background";
import Footer from "../components/Footer";

export default function Contact() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="bg-white min-h-screen flex flex-col">
      <Header />
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <BackgroundPattern1 />
        <div className="mx-auto max-w-3xl py-32 sm:py-48 lg:py-56">
          <div className="text-center space-y-10">
            <p className="md:text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Gostaria de sugerir uma nova fonte de dados ou compartilhar os
              seus próprios dados?
            </p>
            <div className="flex justify-center">
              <button
                onClick={() => openInNewTab("https://t.me/bastostiago")}
                role="link"
                className="flex rounded-md bg-blue-600 px-4 py-3 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 items-center gap-x-3"
              >
                <svg
                  className="h-10 w-10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 496 512"
                >
                  <path
                    fill="#ffffff"
                    d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"
                  />
                </svg>
                Vamos trocar uma ideia!
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
