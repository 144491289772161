import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";

export default function Environment({
  open,
  setOpen,
  refreshData,
  editMode,
  data,
  setData,
}) {
  const cancelButtonRef = useRef(null);

  const updateData = (e) => {
    const dataSet = {
      ...data,
      [e.target.name]: ["true", "false"].includes(e.target.value)
        ? JSON.parse(e.target.value)
        : e.target.value,
    };
    setData(dataSet);
  };

  const submit = async (e) => {
    e.preventDefault();

    if (editMode) {
      try {
        const docRef = doc(db, "environments", data.id);
        if (data?.delete === "yes") {
          await deleteDoc(docRef);
        } else {
          await updateDoc(docRef, {
            name: data.name,
            description: data.description,
            show_ads: data.show_ads,
          });
        }
        refreshData();
        setOpen(false);
      } catch (e) {
        alert(e);
      }
    } else {
      const content = {
        name: data.name,
        description: data.description,
        show_ads: data.show_ads,
        created_at: serverTimestamp(),
      };
      try {
        await addDoc(collection(db, "environments"), content);
        refreshData();
        setOpen(false);
      } catch (e) {
        alert(e);
      }
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <form onSubmit={submit} action="#" method="POST">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center sm:ml-4 sm:mr-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h2"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Cadastro de Ambiente
                      </Dialog.Title>

                      <div className="mt-2">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          Descrição
                        </label>
                        <div className="mt-2">
                          <input
                            id="description"
                            name="description"
                            type="text"
                            autoComplete="current-description"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.description}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          Nome (Tag)
                        </label>
                        <div className="mt-2">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            autoComplete="current-name"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.name}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="delete"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          Mostra Anúncios?
                        </label>
                        <div className="mt-2">
                          <select
                            id="show_ads"
                            name="show_ads"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            onChange={updateData}
                          >
                            <option value={false} selected={data?.show_ads}>
                              Não
                            </option>
                            <option value={true} selected={data?.show_ads}>
                              Sim
                            </option>
                          </select>
                        </div>
                      </div>
                      {editMode && (
                        <div className="mt-2">
                          <label
                            htmlFor="delete"
                            className="block text-sm font-medium leading-6 text-red-600"
                          >
                            Deletar?
                          </label>
                          <div className="mt-2">
                            <select
                              id="delete"
                              name="delete"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              onChange={updateData}
                            >
                              <option value="no" selected>
                                Não
                              </option>
                              <option value="yes">Sim</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <input
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                      value="Salvar"
                      name="save"
                    />
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
