import Navigator from "../components/Navigator"
import Loader from "../components/Loader";
import { SparklesIcon } from '@heroicons/react/24/outline'
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useState, useEffect, useRef, Fragment } from "react";
import { auth } from "../firebase";
import AddButton from "../components/FloatButton";
import Environment from "./Environment";

export default function Environments() {

  const [tbEnvironments, setTbEnvironments] = useState([]);
  const [openNew, setOpenNew] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editObject, setEditObject] = useState({})

  const fetchData = async () => {
       
    await getDocs(collection(db, "environments"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setTbEnvironments(newData);                            
        })
   
  }

  const newEvent = () => {
    setEditMode(false);
    setEditObject({});
    setOpenNew(true);
  } 

  const editEvent = (obj) => {
    setEditMode(true);
    setEditObject(obj);
    setOpenNew(true);
  } 

  useEffect(()=>{
    fetchData();
  }, [])

  return (
    <>      
      <Environment open={openNew} setOpen={setOpenNew} refreshData={fetchData} editMode={editMode} data={editObject} setData={setEditObject} />
      <Navigator />  
      <div className="min-h-full">
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex">
            <h1 className="flex text-3xl font-bold tracking-tight text-indigo-600">Ambientes</h1>
            { auth.currentUser && 
              <div className="relative w-full items-end flex-col flex">
                <AddButton  action={newEvent} />
              </div>
            }
          </div>
        </header>
        <main>
            {tbEnvironments.length < 1 ? (
              <Loader />
            ) : (
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <ul role="list" className="divide-y divide-gray-100">
                    {tbEnvironments.map((environ) => (
                        <li key={environ.id} className="flex gap-x-2 py-5">
                            <Link to={ '/' + environ.name}>
                                <div className="flex min-w-0 gap-x-4">
                                    <SparklesIcon className="h-12 w-12 flex-none rounded-full bg-indigo-600 p-3 text-white" />
                                    <div className="min-w-0 flex-auto">
                                      <p className="text-sm font-semibold leading-6 text-indigo-600">{environ.description}</p>
                                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">{environ.name}</p>                                      
                                    </div>                                                                                 
                                </div>
                            </Link>  
                            {auth.currentUser &&
                              <button onClick={() => editEvent(environ)} className="bg-gradient-to-r from-red-400 to-red-400 text-xs text-white px-3 py-1 rounded-lg cursor-pointer">Editar</button>                               
                            } 
                        </li>
                    ))}
                </ul>
              </div>
            )}
            
        </main>
      </div>      
    </>
  )
}
