import { useEffect, useState } from "react";
import axios from "axios";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

export default function Indicators({ ds_data }) {
  const [indicatorsData, setindicatorsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(ds_data.indicators_url_service)
      .then((response) => {
        response.status === 200 &&
          setindicatorsData(response.data[0].indicators);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="w-full flex text-right items-end pt-2">
        <div className="ml-auto flex gap-x-1">
          <button
            onClick={getData}
            className={`${
              isLoading ? "bg-indigo-500" : "bg-indigo-600"
            } text-white px-3.5 py-2.5 text-sm rounded-lg cursor-pointer inline-flex`}
            disabled={isLoading}
          >
            <ArrowPathIcon
              className={`w-5 mr-1 ${isLoading && "animate-spin"}`}
            />
            {`${isLoading ? "Atualizando" : "Atualizar"}`}
          </button>
        </div>
      </div>
      <div className="max-w-full flex pt-2 flex-wrap gap-2 sm:justify-center md:justify-start">
        {isLoading && indicatorsData.length < 1 && (
          <div className="sm:w-full md:max-w-sm min-h-[150px] rounded-lg border bg-white/80 shadow-md p-4 flex items-center space-x-4 animate-pulse">
            <div class="rounded-full bg-slate-200 h-10 w-10 "></div>
            <div class="flex-1 space-y-6 py-1">
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="space-y-3">
                <div class="grid grid-cols-3 gap-4">
                  <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                  <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                </div>
                <div class="h-2 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        )}
        {indicatorsData.map((obj) => (
          <div
            className={`sm:w-full md:max-w-sm min-h-[150px] rounded-lg border bg-white/80 shadow-md p-4 flex items-center ${
              isLoading && "animate-pulse transform"
            }`}
          >
            <div className="flex-col">
              <p class="text-sm text-gray-600">{obj.key}</p>
              <div class="text-indigo-600 font-bold text-3xl mb-2">
                {obj.value}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
