import { PlusIcon } from "@heroicons/react/24/solid"

export default function AddButton(props) {

    return (
        <>        
        <div className="group" onClick={props.action}>            
            <div className = "text-white shadow-xl flex items-center justify-center p-3 rounded-full bg-gradient-to-r from-red-400 to-red-400 z-50">
                <PlusIcon className="w-4 h-4 group-hover:rotate-90 transition  transition-all duration-[0.6s]" />
            </div>
        </div>
        </>        
    )
}

