
export default function Footer() {
  
  return (          
    <footer className="h-full bg-gray-900 text-white py-4 mt-auto">
      <div className="container mx-auto text-center">
          <p>&copy; 2023 Jedizer.</p>
      </div>
    </footer>    
  )
}
