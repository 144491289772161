import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './home/Home';
import Contact from './contact/Contact';
import Enviroments from './environments/Environments';
import EnvironmentView from './environments/EnvironmentView';
import DataSources from './data_sources/DataSources';
import DataSourceView from './data_sources/DataSourceView';
import NotFound from './components/NotFound';
import Login from './admin/Login';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },  
  {
    path: "/environments",
    element: <Enviroments />,
  },
  {
    path: "/:env_name",
    element: <EnvironmentView />,
  },
  {
    path: "/datasources",
    element: <DataSources />,
  },
  {
    path: "/:env_name/:ds_name",
    element: <DataSourceView />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: <Login />,
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
