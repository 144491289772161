import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { Switch } from "@headlessui/react";

export default function DataSource({
  open,
  setOpen,
  refreshData,
  editMode,
  data,
  setData,
}) {
  const cancelButtonRef = useRef(null);
  const [enabled, setEnabled] = useState(false);

  const updateToggle = (active) => {
    setEnabled(active);
    const content = {
      ...data,
      status: active ? 1 : 0,
    };
    setData(content);
  };

  const updateData = (e) => {
    const content = {
      ...data,
      [e.target.name]: e.target.value,
    };
    setData(content);
  };

  const submit = async (e) => {
    e.preventDefault();

    if (editMode) {
      try {
        const docRef = doc(db, "datasources", data.id);
        if (data?.delete === "yes") {
          await deleteDoc(docRef);
        } else {
          delete data.delete;
          delete data.created_at;
          await updateDoc(docRef, data);
        }
        refreshData();
        setOpen(false);
      } catch (e) {
        alert(e);
      }
    } else {
      delete data.delete;
      const content = {
        ...data,
        created_at: serverTimestamp(),
      };
      try {
        addDoc(collection(db, "datasources"), content);
        refreshData();
        setOpen(false);
      } catch (e) {
        alert(e);
      }
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg lg:max-w-[50%]">
                <form onSubmit={submit} action="#" method="POST">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center sm:ml-4 sm:mr-4 sm:mt-0 sm:text-left">
                      <div className="flex">
                        <Dialog.Title
                          as="h2"
                          className="text-base font-semibold leading-6 text-gray-900 w-full"
                        >
                          Cadastro de Data Source
                        </Dialog.Title>

                        <div className="flex gap-x-2 items-end">
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium leading-6 text-indigo-600"
                          >
                            Ativo?
                          </label>
                          <Switch
                            checked={data?.status === 1 ? true : false}
                            onChange={updateToggle}
                            className={`${
                              data?.status === 1 ? "bg-blue-600" : "bg-gray-200"
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                          >
                            <span className="sr-only">
                              Enable notifications
                            </span>
                            <span
                              className={`${
                                data?.status === 1
                                  ? "translate-x-6"
                                  : "translate-x-1"
                              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                        </div>
                      </div>

                      <div className="mt-2">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          Descrição
                        </label>
                        <div className="mt-2">
                          <input
                            id="description"
                            name="description"
                            type="text"
                            autoComplete="current-description"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.description}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          Nome (Tag)
                        </label>
                        <div className="mt-2">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            autoComplete="current-name"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.name}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="long_description"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          Descrição Longa
                        </label>
                        <div className="mt-2">
                          <textarea
                            id="long_description"
                            name="long_description"
                            autoComplete="current-long_description"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.long_description}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="update_information"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          Taxa de Atualização
                        </label>
                        <div className="mt-2">
                          <input
                            id="update_information"
                            name="update_information"
                            type="text"
                            autoComplete="current-update_information"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.update_information}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="origin_url"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          URL da Fonte dos Dados
                        </label>
                        <div className="mt-2">
                          <input
                            id="origin_url"
                            name="origin_url"
                            type="url"
                            autoComplete="current-origin_url"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.origin_url}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-1 rounded md:my-10 dark:bg-gray-700" />
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="indicators_title"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          Título do Painel de Indicadores
                        </label>
                        <div className="mt-2">
                          <input
                            id="indicators_title"
                            name="indicators_title"
                            type="text"
                            autoComplete="current-indicators_title"
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.indicators_title}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="indicators_url_service"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          URL do Webservice de Indicadores
                        </label>
                        <div className="mt-2">
                          <input
                            id="indicators_url_service"
                            name="indicators_url_service"
                            type="url"
                            autoComplete="current-indicators_url_service"
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.indicators_url_service}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="graph_url"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          URL do Gráfico
                        </label>
                        <div className="mt-2">
                          <input
                            id="graph_url"
                            name="graph_url"
                            type="url"
                            autoComplete="current-graph_url"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.graph_url}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="google_sheet_url"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          URL da Planilha do Google
                        </label>
                        <div className="mt-2">
                          <input
                            id="google_sheet_url"
                            name="google_sheet_url"
                            type="url"
                            autoComplete="current-google_sheet_url"
                            required
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.google_sheet_url}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor="map_url"
                          className="block text-sm font-medium leading-6 text-indigo-600"
                        >
                          URL do Mapa
                        </label>
                        <div className="mt-2">
                          <input
                            id="map_url"
                            name="map_url"
                            type="url"
                            autoComplete="current-map_url"
                            className="block w-full rounded-md border-0 px-1.5 py-1.5 text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={updateData}
                            value={data?.map_url}
                          />
                        </div>
                      </div>

                      {editMode && (
                        <div className="mt-2">
                          <div className="mt-2">
                            <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-1 rounded md:my-10 dark:bg-gray-700" />
                          </div>
                          <label
                            htmlFor="delete"
                            className="block text-sm font-medium leading-6 text-red-600"
                          >
                            Deletar?
                          </label>
                          <div className="mt-2">
                            <select
                              id="delete"
                              name="delete"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                              onChange={updateData}
                            >
                              <option value="no" selected>
                                Não
                              </option>
                              <option value="yes">Sim</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <input
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                      value="Salvar"
                      name="save"
                    />
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
