import { useEffect, useState } from "react";

export default function Donate() {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Altere o valor 100 para a quantidade de rolagem desejada para ativar o comportamento
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-full flex z-50 items-center justify-center p-2 bg-bege text-sm ${
        isFixed ? "fixed top-0 left-0 right-0" : ""
      }`}
    >
      <div>Gostou? É útil para você?</div>
      <div className="ml-2 rounded-full text-xs text-gray-600 ring-1 ring-red-400/10 hover:ring-red-400/20 bg-yellow-800 hover:bg-yellow-700 align-middle inline-flex items-center justify-center px-3 py-1.5 min-w-fit">
        <a
          href="https://mepagaumcafe.com.br/tiagobastos/"
          className="font-medium text-white flex items-center"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline-flex w-4 h-4"
            viewBox="0 0 640 512"
          >
            <path
              fill="#ffffff"
              d="M88 0C74.7 0 64 10.7 64 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C120.5 112.3 128 119.9 128 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C119.5 47.7 112 40.1 112 24c0-13.3-10.7-24-24-24zM32 192c-17.7 0-32 14.3-32 32V416c0 53 43 96 96 96H288c53 0 96-43 96-96h16c61.9 0 112-50.1 112-112s-50.1-112-112-112H352 32zm352 64h16c26.5 0 48 21.5 48 48s-21.5 48-48 48H384V256zM224 24c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C232.5 112.3 240 119.9 240 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C231.5 47.7 224 40.1 224 24z"
            />
          </svg>
          Me Pague um Café!
        </a>
      </div>
    </div>
  );
}
