import { useEffect, useState } from "react";
import axios from "axios";
import { ArrowPathIcon, ClockIcon } from "@heroicons/react/24/outline";

const BASE_SHEET_SERVICE = process.env.REACT_APP_BASE_URL_SHEET_SERVICE;

export default function GoogleSheetTable({ ds_data }) {
  const [googleSheetData, setGoogleSheetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDataSheet = () => {
    const google_sheet_id = ds_data?.google_sheet_url?.split("/")[5];
    if (google_sheet_id) {
      setIsLoading(true);
      axios
        .get(BASE_SHEET_SERVICE, {
          params: {
            planilhaId: google_sheet_id,
            pageIni: "1",
            pageFin: "100",
          },
        })
        .then((response) => {
          response.status === 200 && setGoogleSheetData(response.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    getDataSheet();
  }, []);

  return (
    <>
      <div className="w-full flex text-right items-end pt-2">
        <div className="ml-auto flex gap-x-1">
          <button
            onClick={getDataSheet}
            className={`${
              isLoading ? "bg-indigo-500" : "bg-indigo-600"
            } text-white px-3.5 py-2.5 text-sm rounded-lg cursor-pointer inline-flex`}
            disabled={isLoading}
          >
            <ArrowPathIcon
              className={`w-5 mr-1 ${isLoading && "animate-spin"}`}
            />
            {`${isLoading ? "Atualizando" : "Atualizar"}`}
          </button>
          <button
            onClick={() => window.open(ds_data.google_sheet_url, "_blank")}
            className="bg-indigo-600 text-white px-3.5 py-2.5 text-sm rounded-lg cursor-pointer inline-flex"
          >
            <ClockIcon className="w-5 mr-1" />
            Ver Histórico
          </button>
        </div>
      </div>
      <div className="max-w-full pt-2">
        {googleSheetData.length > 0 && (
          <div className="bg-white shadow-md rounded max-w-full overflow-x-auto">
            <table
              className={`border-collapse w-full ${
                Object.keys(googleSheetData[0]).length > 10 &&
                "lg:text-[0.7rem]"
              } sm:text-xs`}
            >
              <thead>
                <tr>
                  {Object.keys(googleSheetData[0])
                    .filter((title) => title)
                    .map((title) => (
                      <th className="w-full px-3 font-bold uppercase bg-blue-200 text-indigo-600 border border-gray-400 hidden lg:table-cell lg:px-4 lg:py-2">
                        {title}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {googleSheetData.map((obj_line) => (
                  <>
                    {Object.values(obj_line).filter((value) => value).length >
                      0 && (
                      <tr className="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                        {Object.entries(obj_line)
                          .filter(([key, value]) => key)
                          .map(([key, value]) => (
                            <td class="w-full text-indigo-600 text-center border border-b whitespace-no-wrap lg:table-cell relative lg:static lg:px-4 lg:py-2 sm:py-7">
                              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 font-bold uppercase">
                                {key}
                              </span>
                              {value}
                            </td>
                          ))}
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
