import Navigator from "../components/Navigator";
import Loader from "../components/Loader";
import { CircleStackIcon } from "@heroicons/react/24/outline";
import {
  collection,
  getDocs,
  getDoc,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import { useState, useEffect } from "react";

export default function DataSources() {
  const [tbDataSources, setTbDataSources] = useState([]);

  const fetchData = async () => {
    const dsRef = collection(db, "datasources");
    const querySnapshot = await getDocs(
      query(dsRef, orderBy("created_at", "desc"))
    );

    const newData = [];
    for (const doc of querySnapshot.docs) {
      const dataSource = doc.data();
      const environmentRef = dataSource.environment;

      // Fetch the environment data based on the reference
      const environmentDoc = await getDoc(environmentRef);
      const environmentData = environmentDoc.data();

      // Create a new object that includes the environment data
      const combinedData = {
        ...dataSource,
        id: doc.id,
        environment: environmentData,
      };

      newData.push(combinedData);
    }

    setTbDataSources(newData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Navigator />
      <div className="min-h-full">
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-indigo-600">
              Data Sources
            </h1>
          </div>
        </header>
        <main>
          {tbDataSources.length < 1 ? (
            <Loader />
          ) : (
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <ul className="divide-y divide-gray-100">
                {tbDataSources.map((ds, id) => (
                  <li key={id} className="flex justify-between gap-x-6 py-5">
                    <a href={"/" + ds.environment.name + "/" + ds.name}>
                      <div className="flex min-w-0 gap-x-4">
                        <CircleStackIcon className="h-12 w-12 flex-none rounded-full bg-indigo-600 p-3 text-white" />
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-indigo-600">
                            {ds.description}
                          </p>
                          <p className="mt-1 text-xs leading-5 text-gray-500">
                            {ds.environment.description}
                          </p>
                        </div>
                      </div>
                    </a>
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-xs text-right leading-6 text-indigo-600">
                        Atualizado a cada {ds.update_information}
                      </p>
                      {ds.status === 1 ? (
                        <div className="mt-1 flex items-center gap-x-1.5">
                          <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                            <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                          </div>
                          <p className="text-xs leading-5 text-gray-500">
                            Ativo
                          </p>
                        </div>
                      ) : (
                        <div className="mt-1 flex items-center gap-x-1.5">
                          <div className="flex-none rounded-full bg-red-500/20 p-1">
                            <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
                          </div>
                          <p className="text-xs leading-5 text-gray-500">
                            Inativo
                          </p>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </main>
      </div>
    </>
  );
}
