import Navigator from "../components/Navigator";
import NotFound from "../components/NotFound";
import Loader from "../components/Loader";
import Donate from "../components/Donate";
import {
  ChartBarIcon,
  TableCellsIcon,
  MapIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import {
  ChartBarIcon as ChartBarIconSolid,
  TableCellsIcon as TableCellsIconSolid,
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  MapIcon as MapIconSolid,
  Squares2X2Icon as Squares2X2IconSolid,
} from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import { collection, getDocs, getDoc, query, where } from "firebase/firestore";
import { db } from "../firebase";
import GoogleSheetTable from "../components/GoogleSheetTable";
import Indicators from "../components/Indicators";

export default function DataSourceView() {
  const { env_name, ds_name } = useParams();

  const [tbDataSources, setTbDataSources] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);

  const fetchData = async () => {
    const environmentRef = collection(db, "environments");
    const queryEnvRef = query(environmentRef, where("name", "==", env_name));
    const queryEnvSnapshot = await getDocs(queryEnvRef);
    if (queryEnvSnapshot.empty) {
      return setNotFound(true);
    }
    const env_doc = queryEnvSnapshot.docs[0];

    const dataSourcesRef = collection(db, "datasources");
    const queryRef = query(
      dataSourcesRef,
      where("name", "==", ds_name),
      where("environment", "==", env_doc.ref)
    );
    const querySnapshot = await getDocs(queryRef);
    if (querySnapshot.empty) {
      return setNotFound(true);
    }
    const ds_doc = querySnapshot.docs[0];
    const data_to_set = ds_doc.data();

    setTbDataSources({
      ...data_to_set,
      id: ds_doc.id,
      environment: env_doc.data(),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const selected_tab_style =
    "inline-flex items-center justify-center text-sm p-2 pr-4 pl-4 text-indigo-600 border-b-2 border-indigo-600 min-w-[20]";
  const unselected_tab_style =
    "inline-flex items-center justify-center text-sm p-2 pr-4 pl-4 text-gray-500";

  // Function to refresh the iframe
  const refreshIframe = () => {
    setIframeKey(iframeKey + 1);
  };

  return (
    <>
      <Navigator />
      {notFound ? (
        <NotFound />
      ) : (
        <div key={tbDataSources.id} className="min-h-full">
          <Donate />
          <header className="bg-white shadow">
            {tbDataSources.length < 1 ? (
              <Loader />
            ) : (
              <div className="mx-auto px-4 py-6 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-indigo-600">
                  {tbDataSources.description}
                </h1>
                <a href={"/" + tbDataSources.environment?.name}>
                  <h2 className="text font-semibold tracking-tight text-indigo-600">
                    {tbDataSources.environment?.description}
                  </h2>
                </a>
                <h3 className="text-sm tracking-tight text-indigo-500 pt-3">
                  {tbDataSources.long_description}
                </h3>
                {tbDataSources.status === 1 ? (
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                      <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                    </div>
                    <p className="text-xs leading-5 text-gray-500">
                      Ativo (atualizado a cada{" "}
                      {tbDataSources.update_information})
                    </p>
                  </div>
                ) : (
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <div className="flex-none rounded-full bg-red-500/20 p-1">
                      <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
                    </div>
                    <p className="text-xs leading-5 text-gray-500">Inativo</p>
                  </div>
                )}
                <p className="inline-block align-middle text-xs leading-5 text-indigo-600 pt-3">
                  Fonte:
                  <a
                    href={tbDataSources.origin_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    {tbDataSources.origin_url}{" "}
                    <ArrowTopRightOnSquareIcon className=" inline-flex w-3" />
                  </a>
                </p>
              </div>
            )}
          </header>
          <main>
            <div className="mx-auto px-4 py-6 sm:px-6 lg:px-8">
              {tbDataSources.length < 1 ? (
                <Loader />
              ) : (
                <Tab.Group>
                  <Tab.List className="border-b-2">
                    {tbDataSources.indicators_url_service && (
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selected
                                ? selected_tab_style
                                : unselected_tab_style
                            }
                          >
                            {selected ? (
                              <Squares2X2IconSolid className="h-4 w-4 mr-1" />
                            ) : (
                              <Squares2X2Icon className="h-4 w-4 mr-1" />
                            )}
                            {tbDataSources.indicators_title}
                          </button>
                        )}
                      </Tab>
                    )}
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected ? selected_tab_style : unselected_tab_style
                          }
                        >
                          {selected ? (
                            <ChartBarIconSolid className="h-4 w-4 mr-1" />
                          ) : (
                            <ChartBarIcon className="h-4 w-4 mr-1" />
                          )}
                          Gráfico
                        </button>
                      )}
                    </Tab>
                    <Tab as={Fragment}>
                      {({ selected }) => (
                        /* Use the `selected` state to conditionally style the selected tab. */
                        <button
                          className={
                            selected ? selected_tab_style : unselected_tab_style
                          }
                        >
                          {selected ? (
                            <TableCellsIconSolid className="h-4 w-4 mr-1" />
                          ) : (
                            <TableCellsIcon className="h-4 w-4 mr-1" />
                          )}
                          Planilha
                        </button>
                      )}
                    </Tab>
                    {tbDataSources.map_url && (
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selected
                                ? selected_tab_style
                                : unselected_tab_style
                            }
                          >
                            {selected ? (
                              <MapIconSolid className="h-4 w-4 mr-1" />
                            ) : (
                              <MapIcon className="h-4 w-4 mr-1" />
                            )}
                            Mapa
                          </button>
                        )}
                      </Tab>
                    )}
                  </Tab.List>
                  <Tab.Panels>
                    {tbDataSources.indicators_url_service && (
                      <Tab.Panel>
                        <Indicators ds_data={tbDataSources} />
                      </Tab.Panel>
                    )}
                    <Tab.Panel>
                      <div className="w-full flex items-start pt-2">
                        <div className="ml-auto">
                          <button
                            onClick={refreshIframe}
                            className="bg-indigo-600 text-white px-3.5 py-2.5 text-sm rounded-lg cursor-pointer inline-flex"
                          >
                            <ArrowPathIcon className="w-5 mr-1" /> Atualizar
                          </button>
                        </div>
                      </div>
                      <div className="md:aspect-square sm:aspect-[9/16] pt-2">
                        <iframe
                          key={iframeKey}
                          className="w-full h-full"
                          src={tbDataSources.graph_url}
                          loading="eager"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel>
                      <GoogleSheetTable ds_data={tbDataSources} />
                    </Tab.Panel>
                    {tbDataSources.map_url && (
                      <Tab.Panel>
                        <div className="w-full flex items-start pt-2">
                          <div className="ml-auto">
                            <button
                              onClick={refreshIframe}
                              className="bg-indigo-600 text-white px-3.5 py-2.5 text-sm rounded-lg cursor-pointer inline-flex"
                            >
                              <ArrowPathIcon className="w-5 mr-1" /> Atualizar
                            </button>
                          </div>
                        </div>
                        <div className="md:aspect-video sm:aspect-[9/16] pt-2">
                          <iframe
                            key={iframeKey}
                            className="w-full h-full"
                            src={tbDataSources.map_url}
                            loading="eager"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </Tab.Panel>
                    )}
                  </Tab.Panels>
                </Tab.Group>
              )}
            </div>
          </main>
        </div>
      )}
    </>
  );
}
