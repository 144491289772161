import Navigator from "../components/Navigator";
import NotFound from "../components/NotFound";
import Loader from "../components/Loader";
import { CircleStackIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import { useState, useEffect } from "react";
import { auth } from "../firebase";
import AddButton from "../components/FloatButton";
import DataSource from "../data_sources/DataSource";

export default function EnvironmentView() {
  const { env_name } = useParams();

  const [tbEnvironment, setTbEnvironment] = useState({});
  const [tbDataSources, setTbDataSources] = useState([]);
  const [reachDS, setReachDS] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editObject, setEditObject] = useState({});

  const fetchData = async () => {
    const environmentRef = collection(db, "environments");
    const queryRef = query(environmentRef, where("name", "==", env_name));
    const querySnapshot = await getDocs(queryRef);
    if (querySnapshot.empty) {
      setNotFound(true);
    } else {
      const reg = querySnapshot.docs[0];
      const environmentData = reg.data();
      setTbEnvironment({
        ...environmentData,
        id: reg.id,
        ref: reg.ref,
      });

      const datasourcesRef = collection(db, "datasources");
      const queryDsRef = query(
        datasourcesRef,
        where("environment", "==", reg.ref),
        orderBy("created_at", "desc")
      );
      const queryDsSnapshot = await getDocs(queryDsRef);
      const newData = queryDsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTbDataSources(newData);
      setReachDS(true);
    }
  };

  const newEvent = () => {
    setEditMode(false);
    setEditObject({ environment: tbEnvironment.ref, status: 1 });
    setOpenNew(true);
  };

  const editEvent = (obj) => {
    setEditMode(true);
    setEditObject(obj);
    setOpenNew(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DataSource
        open={openNew}
        setOpen={setOpenNew}
        refreshData={fetchData}
        editMode={editMode}
        data={editObject}
        setData={setEditObject}
      />
      <Navigator />
      {notFound ? (
        <NotFound />
      ) : (
        <div className="min-h-full">
          <header className="bg-white shadow">
            {!tbEnvironment.description ? (
              <Loader />
            ) : (
              <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-indigo-600">
                  {tbEnvironment.description}
                </h1>
                <Link to="/datasources">
                  <h2 className="text font-semibold tracking-tight text-indigo-600">
                    Data Sources
                  </h2>
                </Link>
                {auth.currentUser && (
                  <div className="relative w-full items-end flex-col flex">
                    <AddButton action={newEvent} />
                  </div>
                )}
              </div>
            )}
          </header>
          <main>
            {!reachDS ? (
              <Loader />
            ) : (
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <ul role="list" className="divide-y divide-gray-100">
                  {tbDataSources.map((ds) => (
                    <li
                      key={ds.id}
                      className="flex justify-between gap-x-6 py-5"
                    >
                      <div className="flex gap-x-4">
                        <a href={"/" + tbEnvironment.name + "/" + ds.name}>
                          <div className="flex min-w-0 gap-x-4">
                            <CircleStackIcon className="h-12 w-12 flex-none rounded-full bg-indigo-600 p-3 text-white" />
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm font-semibold leading-6 text-indigo-600">
                                {ds.description}
                              </p>
                              <p className="mt-1 text-xs leading-5 text-gray-500">
                                {tbEnvironment.description}
                              </p>
                            </div>
                          </div>
                        </a>
                        {auth.currentUser && (
                          <button
                            onClick={() => editEvent(ds)}
                            className="bg-gradient-to-r from-red-400 to-red-400 text-xs text-white px-3 py-1 rounded-lg cursor-pointer"
                          >
                            Editar
                          </button>
                        )}
                      </div>

                      <div className="hidden sm:flex sm:flex-col sm:items-end">
                        <p className="text-xs text-right leading-6 text-indigo-600">
                          Atualizado a cada {ds.update_information}
                        </p>
                        {ds.status === 1 ? (
                          <div className="mt-1 flex items-center gap-x-1.5">
                            <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                              <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                            </div>
                            <p className="text-xs leading-5 text-gray-500">
                              Ativo
                            </p>
                          </div>
                        ) : (
                          <div className="mt-1 flex items-center gap-x-1.5">
                            <div className="flex-none rounded-full bg-red-500/20 p-1">
                              <div className="h-1.5 w-1.5 rounded-full bg-red-500" />
                            </div>
                            <p className="text-xs leading-5 text-gray-500">
                              Inativo
                            </p>
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </main>
        </div>
      )}
    </>
  );
}
